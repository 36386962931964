import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/abdullatieffathoni/Code/javascript/tds-web/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageDescription = makeShortcode("PageDescription");
const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const ImageCard = makeShortcode("ImageCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PageDescription mdxType="PageDescription">
      <p><inlineCode parentName="p">{`Tabs`}</inlineCode>{` merupakan sebuah komponen yang digunakan untuk mengatur beberapa konten atau panel ke dalam satu jendela.`}</p>
    </PageDescription>
    <h2>{`Variant`}</h2>
    <Row className="image-card-group" mdxType="Row">
      <Column colMd={6} colLg={6} noGutterSm mdxType="Column">
    <ImageCard title="Default" titleColor="dark" aspectRatio="16:9" mdxType="ImageCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "640px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "56.25%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsSAAALEgHS3X78AAAApElEQVQoz62S0QrDIAxF+/+fOMZgL20da7epiaYUMq1zVNxoHnw4eInxcg3pwC3cki4Li7Qh01TVK8O9cdJUNB7pfP5MaKL+1A34DTxIXiTMl5H4sJ+Q1WzZ08qDum+gX/l6Az71ljUQz0/DL4OVeWUYMeHBGEy0dakxMGnPlxH4PFhWD1eMpDKUEJN7SqBfviPZf/dvQgmAgoSSoYv3sPlit+INkkVWiBdwSF8AAAAASUVORK5CYII=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Square",
              "title": "Square",
              "src": "/static/4bcbc662eab8f6c27c40b2d2cdbadeaa/d67c7/default.png",
              "srcSet": ["/static/4bcbc662eab8f6c27c40b2d2cdbadeaa/7fc1e/default.png 288w", "/static/4bcbc662eab8f6c27c40b2d2cdbadeaa/a5df1/default.png 576w", "/static/4bcbc662eab8f6c27c40b2d2cdbadeaa/d67c7/default.png 640w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
    `}</span>
  </ImageCard>
      </Column>
      <Column colMd={6} colLg={6} noGutterSm mdxType="Column">
    <ImageCard title="Badge" titleColor="dark" aspectRatio="16:9" mdxType="ImageCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "640px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "56.25%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsSAAALEgHS3X78AAAAzklEQVQoz62SbQ+CIBSF/f8/sMwZyzQN01RQAW07gYnL3lbND2f3wuWe8cB1GtFhSTk2qVs16j7/XtZjNFR/mbwydWwiZAelOshRrd5rZQ+hLhOOOSNV/xZ3uqFJyLHBOuTw4hYrQrGPT0hoBtfzsd2FKFgDkuh6VOl1hFKvzyUHzQqQIJob8kbBT2q4EcfmYCJDQOuhKS/YEM3hNGeI0wIVF7pHgtVikK3PkA2u6vopWjSDbXRDNk/wjGzrM+TFPuWXGXsckY9zuPhgL6UrBj1T0SA3xL4AAAAASUVORK5CYII=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Square",
              "title": "Square",
              "src": "/static/e15242a41936c60c60cc567f478d9430/d67c7/badge.png",
              "srcSet": ["/static/e15242a41936c60c60cc567f478d9430/7fc1e/badge.png 288w", "/static/e15242a41936c60c60cc567f478d9430/a5df1/badge.png 576w", "/static/e15242a41936c60c60cc567f478d9430/d67c7/badge.png 640w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
    `}</span>
  </ImageCard>
      </Column>
      <Column colMd={6} colLg={6} noGutterSm mdxType="Column">
    <ImageCard title="Icon" titleColor="dark" aspectRatio="16:9" mdxType="ImageCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "640px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "56.25%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsSAAALEgHS3X78AAAAs0lEQVQoz82S2QrCMBBF+//fJ0hd6tIoarUxS7NVuCaRSqiiCHnw4WaWMJc5JEWnHXKqCIdUFkP8VelcFsN09mmYNrTpYayDtSF3UL7W9hYV7kMdlGIOsy8bcmkwqwhKwjHZcNRnjVPTYr5cYVGtQZnEtt7j6HtNy3Ch/P2GoVA+UuENCUO5E5gSgerQgQmF9iqiRGeiaehxqaO+IlvXe9wezj3Qx5hj3I/IWR8l6z/8a8M7ZStVCNJKaGEAAAAASUVORK5CYII=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Square",
              "title": "Square",
              "src": "/static/e3594c09b3d4ced9e623f762345d4064/d67c7/icon.png",
              "srcSet": ["/static/e3594c09b3d4ced9e623f762345d4064/7fc1e/icon.png 288w", "/static/e3594c09b3d4ced9e623f762345d4064/a5df1/icon.png 576w", "/static/e3594c09b3d4ced9e623f762345d4064/d67c7/icon.png 640w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
    `}</span>
  </ImageCard>
      </Column>
      <Column colMd={6} colLg={6} noGutterSm mdxType="Column">
    <ImageCard title="Icon Top" titleColor="dark" aspectRatio="16:9" mdxType="ImageCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "640px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "56.25%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsSAAALEgHS3X78AAAA7ElEQVQoz7VSa4vCMBDM//93J3eCoJ4varmzad7Z1sJcEgwk6oGCfthMZrOZzmbLrB/xymBxMW5Axrx/lmdklVhAR+f/L4azkruCZ2T1Vwi8l3AXIW0JUtm09+GyVBrauMSjGBcq1VQOM4kFbWcwXzXgmkDjhPX3Dp/zBWiYQm7E17LB6sBDJxOU8Viut/jtROU6OYyOTpIw2yp8bILoXkPZIRW3P11y3imPY084co+mPaGXBlJ79KGDm5YjiQIiOBOGEuqL6/ymLrUdcyNEIXI9nGrKrojbKZb8/OCUr8LeyVXnd0TZW37sV8YfxAZUAXj6xsIAAAAASUVORK5CYII=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Square",
              "title": "Square",
              "src": "/static/dc012ea99a8b2aa660e78aea052f13e4/d67c7/icon-top.png",
              "srcSet": ["/static/dc012ea99a8b2aa660e78aea052f13e4/7fc1e/icon-top.png 288w", "/static/dc012ea99a8b2aa660e78aea052f13e4/a5df1/icon-top.png 576w", "/static/dc012ea99a8b2aa660e78aea052f13e4/d67c7/icon-top.png 640w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
    `}</span>
  </ImageCard>
      </Column>
    </Row>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      